import {StructureObservable} from "../Core/Observables/StructureObservable";
import {computed} from "mobx";
import {DIG_OUT, IS_NOT_NULL, IS_NULL, PIPE} from "../Core/Helpers/Helpers.misc";
import accessPointService from "./AccessPoint.service";
import {POST} from "../Common/Constants/EW_Routes.cnst";
import {LOGIN, ROLE} from "../Common/Constants/EW_User.cnst";
import {EW_Toggleable} from "../Common/Classes/EW_Toggleable.class";

export interface I_EW_User {
  [LOGIN]: string | null,
  [ROLE]: string | null
}

export class UserService extends EW_Toggleable {

  public static getDefaultUser = () => ({
    [LOGIN]: null,
    [ROLE]: null
  } as I_EW_User);

  private readonly _user: StructureObservable = new StructureObservable(UserService.getDefaultUser());

  public login = (credentials) => PIPE(
    accessPointService.sendPost(POST.SIGN_IN, credentials),
    user => this._user.setValue(user),
    () => this._user.value
  );

  public logout = () => PIPE(
    accessPointService.sendPost(POST.SIGN_OUT),
    () => this._user.resetValue()
  );

  @computed
  public get isAuthorized (): boolean {
    const
      user = this._user.value || {},
      keys = Object.keys(user);

    return !!keys.length && keys.every(key => IS_NOT_NULL(user[key]));
  }

  @computed
  public get user (): I_EW_User {
    return this._user.value;
  }

  constructor () {
    super();
    accessPointService.hasToken ? accessPointService.sendPost(POST.CHECK_TOKEN)
      .then(user => this._user.setValue(user))
      .catch(err => accessPointService.clearToken())
      .finally(() => this.enable()) : this.enable();
  }

}

export default new UserService();

