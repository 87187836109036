import {AS_FUNCTION, GET_NULL, IS_NOT_NULL, IS_NOT_UNDEFINED, IS_NULL} from '../Helpers/Helpers.misc';
import {
  _GET_COLLECTION_,
  _GET_COPY_,
  _GET_ITEM_, _GET_NESTED_,
  _GET_PROTO_,
  _IS_SELECTED_, _REMOVE_,
  _TOGGLE_,
  AbstractCollection
} from './AbstractCollection';
import {computed, observable, runInAction} from 'mobx';
import {BooleanObservable} from "../Observables/BooleanObservable";

export class AbstractCollectionItem {

  private _hasNested: BooleanObservable = new BooleanObservable();

  private _nestedCollection: AbstractCollection;

  public readonly id: string;

  public readonly index: number;

  public readonly datum: any;

  public createNestedCollection (
    items: any = null,
    trackBy: string = 'id',
    allowMultiselect: boolean = false
  ): AbstractCollection {
    !this._nestedCollection && (this._nestedCollection = new AbstractCollection(trackBy, allowMultiselect, () => this));
    IS_NOT_NULL(items) && this._nestedCollection.replaceItems(items);
    this._hasNested.setValue(true);
    return this._nestedCollection;
  }

  public getCopy () {
    let datum = Object.assign({}, this.datum);
    [_GET_COLLECTION_, _GET_ITEM_, _GET_COPY_, _GET_NESTED_, _IS_SELECTED_, _TOGGLE_, _GET_PROTO_]
      .forEach(method => delete datum[method]);
    datum[_GET_PROTO_] = () => this.datum;
    return datum;
  }

  @computed public get nestedCollection (): AbstractCollection | null | any {
    return this._hasNested.value ? this._nestedCollection : null;
  }

  @computed public get nestedItems (): any[] | null {
    return this._hasNested.value ? this.nestedCollection.items : null;
  }

  constructor (
    id: string,
    index: number,
    datum: any,
    getCollection: any = GET_NULL
  ) {
    const
      self = this,
      _getCollection = (): AbstractCollection => AS_FUNCTION(getCollection);

    this.id = id;
    this.index = index;
    this.datum = Object.assign(datum, {
      [_GET_COLLECTION_]: () => _getCollection(),
      [_GET_ITEM_]: () => _getCollection().getItem(id, false),
      [_GET_COPY_]: () => self.getCopy(),
      [_GET_NESTED_]: (itemsOnly: boolean = true) => itemsOnly ? self._nestedCollection.items : self._nestedCollection,
      [_IS_SELECTED_]: () => _getCollection().isSelected(datum),
      [_TOGGLE_]: (state: boolean | null = null) => _getCollection()[
        IS_NULL(state) ?
          (AS_FUNCTION(datum[_IS_SELECTED_]) ? 'deselect' : 'select') :
          (!state ?  'deselect' : 'select')
       ](id),
      [_REMOVE_]: () => _getCollection().removeItem(id)
    });
  }
}

