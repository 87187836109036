import {AbstractObservable} from '../Abstract/AbstractObservable';
import {action, computed, observable} from 'mobx';
import {IS_OBJECT} from '../Helpers/Helpers.misc';

export class StructureObservable extends AbstractObservable {

  protected readonly _initialValue: any = {};

  @observable.shallow
  protected _value: any;

  @action
  public setValue (value, save: boolean = false): this {
    IS_OBJECT(value) && Object.assign(this._value, value);
    return save ? this.saveValue() : this;
  }

  @action
  public removeKey (key): this {
    delete this._value[key];
    return this;
  }

  @action
  public resetValue (): this {
    this._value = {...this._initialValue};
    return this;
  }

  @computed
  public get value (): any {
    return Object.assign({}, this._value);
  }

  @computed
  public get isEmpty (): boolean {
    return this._value && !Object.keys(this._value).length;
  }

  constructor (initialValue: any = {}) {
    super();
    this._initialValue = initialValue;
    this.resetValue();
  }
}

