import * as d3 from 'd3';
import React, {Suspense, lazy} from "react";
import {AbstractComponent} from "../../Core/Abstract/AbstractComponent";
import {observer} from "mobx-react";
import {BooleanObservable} from "../../Core/Observables/BooleanObservable";
import {EMPTY_STRING, IS_CLOSED, IS_OPENED} from "../../Core/Constants/ViewClasses.cnst";
import {EW_BackgroundImage} from "../EW_BackgroundImage/EW_BackgroundImage";

const
  EW_Modal = lazy(() => import('../EW_Modal/EW_Modal'));

const
  ROOT = 'ew-loader',
  CONTENT = `${ROOT}-content`,
  LOGO = `${ROOT}-logo`;

const
  LOGO_SOURCE = 'logo-dark.png';

@observer
export default class EW_Loader extends AbstractComponent {

  private readonly isOpened: BooleanObservable;

  render () {
    const
      {isOpened, props} = this,
      {className} = props;

    return <Suspense fallback={EMPTY_STRING}>
      <EW_Modal className={`${ROOT} ${className || EMPTY_STRING} size-cover`}
                isOpened={isOpened}>
          <div className={`${CONTENT} size-cover display-flex align-center`}>
            <div className={`${LOGO}`}>
              <EW_BackgroundImage source={LOGO_SOURCE} />
            </div>
          </div>
      </EW_Modal>
    </Suspense>;
  }

  constructor (props) {
    super(props);
    this.isOpened = props.isOpened;
  }
}
