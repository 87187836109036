import {AbstractObservable} from '../Abstract/AbstractObservable';
import {action, observable} from 'mobx';

export class NumberObservable extends AbstractObservable {

  protected readonly _initialValue: number;

  @observable
  protected _value: number;

  @action
  public setValue (value, save: boolean = false): this {
    this._value = +value;
    return save ? this.saveValue() : this;
  }

  constructor (initialValue: number = 0) {
    super();
    this._initialValue = initialValue;
    this.resetValue();
  }

}

