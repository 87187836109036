import React from 'react';
import {AS_ARRAY, AS_FUNCTION, DIG_OUT, IS_FUNCTION} from '../Helpers/Helpers.misc';
import {observer, Provider} from 'mobx-react';
import {AbstractSubscription} from './AbstractSubscription';
import {AbstractPipe} from './AbstractPipe';

const
  SERVICES = [
    'pulseService',
    'sharedService',
    'userService',
    'accessPointService',
    'globalEventsService',
    'routingService'
  ];

@observer
export class AbstractComponent extends React.Component <any, any> {

  protected subscriptions: AbstractSubscription[] = [];

  protected pipes: AbstractPipe[] = [];

  protected services: any = {};
  
  protected registerSubscriptions = (...args) => {
    AS_ARRAY(args).forEach(s => this.subscriptions = this.subscriptions.concat(s));
    return this;
  };

  protected clearSubscriptions = () => {
    this.subscriptions.forEach(s => s && IS_FUNCTION(s.unsubscribe) && s.unsubscribe());
    return this;
  };

  protected registerPipes = (...args) => {
    AS_ARRAY(args).forEach(p => this.pipes = this.pipes.concat(p));
    return this;
  };

  protected clearPipes = () => {
    this.pipes.forEach(p => p && IS_FUNCTION(p.stop) && p.stop());
    return this;
  };
  
  protected getService = (serviceName: string) => DIG_OUT(this.services, serviceName) || null;

  componentWillUnmount(): void {
    this.clearSubscriptions();
    this.clearPipes();
  }
  
  constructor(props, log: boolean = false) {
    super(props);
    SERVICES.forEach(service => props[service] && (this.services[service] = props[service]));
  }
}
