import React from "react";
import {AS_ARRAY, IS_UNDEFINED, DIG_OUT, PIPE} from "../Core/Helpers/Helpers.misc";
import {BooleanObservable} from "../Core/Observables/BooleanObservable";
import {EW_Toggleable} from "../Common/Classes/EW_Toggleable.class";
import {EW_GrowlModel} from "../Components/EW_Growl/EW_Growl.model";
import globalEventsService from './GlobalEvents.service';
import {computed} from "mobx";

export class SharedService extends EW_Toggleable {

  public servicesAreReady: BooleanObservable = new BooleanObservable(false);

  public isLoading: BooleanObservable = new BooleanObservable(true);

  public showLoginForm: BooleanObservable = new BooleanObservable(false);
  
  public showBlogConstructor: BooleanObservable = new BooleanObservable(false);
  
  public menuState: BooleanObservable = new BooleanObservable(false);

  public growl: EW_GrowlModel = new EW_GrowlModel();

  public loadImages = (src): Promise<any> => new Promise((resolve, reject) => {
    const
      _src = AS_ARRAY(src),
      _singleSource = _src.length === 1,
      _loadedImages: HTMLImageElement[] = [],
      _loadImage = (i: number = 0) => {
        if (IS_UNDEFINED(_src[i])) resolve(_singleSource ? _loadedImages[0] : _loadedImages);
        else {
          let image = new Image();
          image.src = _src[i];
          image.onload = () => {
            _loadedImages.push(image);
            _loadImage(i + 1);
          };
          image.onerror = err => _loadImage(i + 1);
        }
      };
    _loadImage();
  });

  public searchImages = (src): Promise<any> => new Promise((resolve) => {
    const
      regExp = /"(BANNER|BANNER_LEFT|BANNER_RIGHT|SOURCE|BACKGROUND)":"(.[^"]*)"/gim,
      str = JSON.stringify(src),
      images: any[] = [];

    let
      cursor;

    while ((cursor = regExp.exec(str)) !== null) {
      let src = DIG_OUT(cursor, '2');
      src && images.push(src);
    }

    resolve(images);
  });

  @computed
  public get isWideScreen (): boolean {
    return globalEventsService.isWideScreen;
  }

  constructor () {
    super();
    this.enable();
  }

}

export default new SharedService();
