import React, {Suspense, lazy} from "react";
import {_TOGGLE_, AbstractCollection} from "../Core/Abstract/AbstractCollection";
import {RouterStore, Route, startRouter} from 'mobx-router';
import {NAME, PATH, LABEL, COMPONENT, ACTIONS} from "../Core/Constants/PropertiesAndAttributes.cnst";
import {computed} from "mobx";
import {AbstractObserver} from "../Core/Abstract/AbstractObserver";
import {AS_FUNCTION, DIG_OUT, DO_NOTHING, RUN_SAFELY} from "../Core/Helpers/Helpers.misc";
import {AbstractSubscription} from "../Core/Abstract/AbstractSubscription";
import {EW_Toggleable} from "../Common/Classes/EW_Toggleable.class";
import {EMPTY_STRING} from "../Core/Constants/ViewClasses.cnst";

const
  EW_HomePage = lazy(() => import('../Routes/EW_HomePage/EW_HomePage')),
  EW_TechnologyPage = lazy(() => import('../Routes/EW_TechnologyPage/EW_TechnologyPage')),
  EW_PeoplePage = lazy(() => import('../Routes/EW_PeoplePage/EW_PeoplePage')),
  EW_AboutPage = lazy(() => import('../Routes/EW_AboutPage/EW_AboutPage')),
  EW_BlogPage = lazy(() => import('../Routes/EW_BlogPage/EW_BlogPage')),
  EW_BlogPostPage = lazy(() => import('../Routes/EW_BlogPostPage/EW_BlogPostPage')),
  EW_404_PAGE = lazy(() => import('../Routes/EW_404_Page/EW_404_Page'));

export const
  IS_MENU_ITEM = 'isMenuItem',
  IS_PRIVATE_PAGE = 'isPrivatePage',
  DISPLAY_IN_HEADER = 'displayInHeader',
  DISPLAY_IN_FOOTER = 'displayInFooter';

export const ROUTES = {
  HOME: 'home',
  TECHNOLOGY: 'technology',
  PEOPLE: 'people',
  ABOUT_US: 'about',
  BLOG: 'blog',
  BLOG_POST: 'blogPost',
  CONTACT_US: 'contacts',
  PRIVACY: 'privacy',
  POLICY: 'policy',
  HELP: 'help',

  DASHBOARD: 'admin-dashboard',
  PAGES_EDITOR: 'pages-editor',
  USERS: 'users',
  MESSAGES: 'messages',
  CV_PAGE: 'cv-page',

  OTHERWISE: 'catchAll'
};

export interface IAppRoute {
  [NAME]: string,
  [PATH]: string,
  [LABEL]: string,
  [COMPONENT]: any,
  [IS_PRIVATE_PAGE]: boolean,
  [DISPLAY_IN_HEADER]: boolean,
  [DISPLAY_IN_FOOTER]: boolean,
  [ACTIONS]?: any
}

export class RoutingService extends EW_Toggleable {

  private readonly router: RouterStore = new RouterStore();

  private readonly _routeChangeObserver: AbstractObserver = new AbstractObserver(() => this.currentRoute);

  public routesCollection: AbstractCollection = new AbstractCollection(NAME).addItems(([

    /** Main Pages */
    {
      [NAME]: ROUTES.HOME,
      [PATH]: `/`,
      [LABEL]: 'HOME',
      [COMPONENT]: <Suspense fallback={EMPTY_STRING}><EW_HomePage /></Suspense>,
      [IS_PRIVATE_PAGE]: false,
      [DISPLAY_IN_HEADER]: true,
      [DISPLAY_IN_FOOTER]: true,
    },
    {
      [NAME]: ROUTES.ABOUT_US,
      [PATH]: `/${ROUTES.ABOUT_US}`,
      [LABEL]: 'ABOUT US',
      [COMPONENT]: <Suspense fallback={EMPTY_STRING}><EW_AboutPage /></Suspense>,
      [IS_PRIVATE_PAGE]: false,
      [DISPLAY_IN_HEADER]: true,
      [DISPLAY_IN_FOOTER]: true,
    },
    {
      [NAME]: ROUTES.TECHNOLOGY,
      [PATH]: `/${ROUTES.TECHNOLOGY}`,
      [LABEL]: 'TECHNOLOGY',
      [COMPONENT]: <Suspense fallback={EMPTY_STRING}><EW_TechnologyPage /></Suspense>,
      [IS_PRIVATE_PAGE]: false,
      [DISPLAY_IN_HEADER]: true,
      [DISPLAY_IN_FOOTER]: true,
    },
    {
      [NAME]: ROUTES.PEOPLE,
      [PATH]: `/${ROUTES.PEOPLE}`,
      [LABEL]: 'PEOPLE',
      [COMPONENT]: <Suspense fallback={EMPTY_STRING}><EW_PeoplePage /></Suspense>,
      [IS_PRIVATE_PAGE]: false,
      [DISPLAY_IN_HEADER]: true,
      [DISPLAY_IN_FOOTER]: true,
    },
    // Temporary disabled
    // {
    //   [NAME]: ROUTES.BLOG,
    //   [PATH]: `/${ROUTES.BLOG}`,
    //   [LABEL]: 'BLOG',
    //   [COMPONENT]: <Suspense fallback={EMPTY_STRING}><EW_BlogPage /></Suspense>,
    //   [IS_PRIVATE_PAGE]: false,
    //   [DISPLAY_IN_HEADER]: true,
    //   [DISPLAY_IN_FOOTER]: true,
    //   [ACTIONS]: 'createBlog'
    // },
    
    /** Content Pages */
    {
      [NAME]: ROUTES.BLOG_POST,
      [PATH]: `/blogPost/:postId`,
      [COMPONENT]: <Suspense fallback={EMPTY_STRING}><EW_BlogPostPage /></Suspense>,
      [IS_PRIVATE_PAGE]: false,
      [DISPLAY_IN_HEADER]: false,
      [DISPLAY_IN_FOOTER]: false
    },

    /** Static Pages */
    // {
    //   [NAME]: ROUTES.PRIVACY,
    //   [PATH]: `/${ROUTES.PRIVACY}`,
    //   [LABEL]: 'PRIVACY',
    //   [COMPONENT]: <EW_PrivacyPage />,
    //   [IS_PRIVATE_PAGE]: false,
    //   [DISPLAY_IN_HEADER]: false,
    //   [DISPLAY_IN_FOOTER]: false,
    // },
    // {
    //   [NAME]: ROUTES.HELP,
    //   [PATH]: `/${ROUTES.HELP}`,
    //   [LABEL]: 'HELP',
    //   [COMPONENT]: <EW_HelpPage />,
    //   [IS_PRIVATE_PAGE]: false,
    //   [DISPLAY_IN_HEADER]: false,
    //   [DISPLAY_IN_FOOTER]: false,
    // },

    /** Admin Pages */
    // {
    //   [NAME]: ROUTES.DASHBOARD,
    //   [PATH]: `/${ROUTES.DASHBOARD}`,
    //   [LABEL]: 'HOME',
    //   [COMPONENT]: <EW_Admin_Dashboard />,
    //   [IS_PRIVATE_PAGE]: true,
    //   [DISPLAY_IN_HEADER]: true,
    //   [DISPLAY_IN_FOOTER]: false,
    // },
    // {
    //   [NAME]: ROUTES.PAGES_EDITOR,
    //   [PATH]: `/${ROUTES.PAGES_EDITOR}`,
    //   [LABEL]: 'PAGES',
    //   [COMPONENT]: <EW_Admin_PagesEditor />,
    //   [IS_PRIVATE_PAGE]: true,
    //   [DISPLAY_IN_HEADER]: true,
    //   [DISPLAY_IN_FOOTER]: false,
    // },
    // {
    //   [NAME]: ROUTES.USERS,
    //   [PATH]: `/${ROUTES.USERS}`,
    //   [LABEL]: 'USERS',
    //   [COMPONENT]: <EW_Admin_Users />,
    //   [IS_PRIVATE_PAGE]: true,
    //   [DISPLAY_IN_HEADER]: true,
    //   [DISPLAY_IN_FOOTER]: false,
    // },
    //
    // {
    //   [NAME]: ROUTES.MESSAGES,
    //   [PATH]: `/${ROUTES.MESSAGES}`,
    //   [LABEL]: 'MESSAGES',
    //   [COMPONENT]: <EW_Admin_Messages />,
    //   [IS_PRIVATE_PAGE]: true,
    //   [DISPLAY_IN_HEADER]: true,
    //   [DISPLAY_IN_FOOTER]: false,
    // }

  ] as IAppRoute[]).map(d => new Route(d)));

  public run = (): RoutingService => {
    const routes = this.routesCollection.items.concat(new Route({
      [NAME]: ROUTES.OTHERWISE,
      [PATH]: `/:routeName`,
      [COMPONENT]: <Suspense fallback={EMPTY_STRING}><EW_404_PAGE /></Suspense>,
      [IS_MENU_ITEM]: false,
      onEnter: (route, params) => {
        const
          {routeName} = params;
        
        [ROUTES.HOME].some(r => r === routeName) && this.goTo();
      }
    }))
      .reduce((_routes, route) => Object.assign(_routes, {[route[NAME]]: route}), {});

    startRouter(routes, this);

    this.onRouteChange(() => AS_FUNCTION(this.currentRoute && this.currentRoute[_TOGGLE_], true), false);

    return this;
  };

  public onRouteChange = (
    fn: any = DO_NOTHING,
    skipFirstCall: boolean = true
  ): AbstractSubscription => this._routeChangeObserver.getSubscription(fn, skipFirstCall);

  public goTo = (routeName: string = ROUTES.HOME, params: any = {}): RoutingService => {
    RUN_SAFELY(this.router.goTo(this.routesCollection.getItem(routeName), params));
    return this;
  };

  @computed
  public get currentRoute () {
    return this.router.currentView;
  }

  @computed
  public get privatePage () {
    return this.currentRoute && this.currentRoute[IS_PRIVATE_PAGE];
  }

  @computed
  public get publicPages () {
    return this.routesCollection.items.filter((d: IAppRoute) => !d[IS_PRIVATE_PAGE]);
  }
  
  @computed
  public get currentParams () {
    return DIG_OUT(this.router, 'params');
  }
  

  constructor () {
    super();
    this.enable();
  }

}

export default new RoutingService();
