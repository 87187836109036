import * as d3 from 'd3';
import {EMPTY_STRING} from '../Constants/ViewClasses.cnst';
import {ALIGN, BOTTOM, CENTER, LEFT, RIGHT, TOP} from '../Constants/PropertiesAndAttributes.cnst';
import {StructureObservable} from "../Observables/StructureObservable";

export const FUNCTION = 'function';
export const STRING = 'string';
export const OBJECT = 'object';
export const RANDOM_WORDS = `minister,cynical,table,dance,silk,prefer,obedient,shrill,accept,snotty,representative,naughty,price,signal,grab,periodic,grass,better,nod,mundane,fairies,noisy,ice,puzzled,view,curtain,fast,way,burst,crash,turn,economic,fix,kick,announce,carve,snobbish,divide,listen,drag`.split(',');
export const CHARS = `abcdefghijklmnopqrstuvwxyz0123456789`.split(EMPTY_STRING);

export const IS_UNDEFINED = (val: any): boolean => val === undefined;

export const IS_NOT_UNDEFINED = (val: any): boolean => !IS_UNDEFINED(val);

export const IS_NULL = (val: any): boolean => val === null;

export const IS_NOT_NULL = (val: any): boolean => !IS_NULL(val);

export const IS_FUNCTION = (val: any): boolean => typeof val === FUNCTION;

export const IS_STRING = (val: any): boolean => typeof val === STRING && !!val.length;

export const IS_ARRAY = (val: any): boolean => Array.isArray(val);

export const IS_OBJECT = (val: any): boolean => typeof(val) === OBJECT && IS_NOT_NULL(val) && !IS_ARRAY(val);

export const IS_PROMISE = (val: any) => val && IS_FUNCTION(val['then']);

export const AS_FUNCTION = (..._args) => {
  let
    args = Array.from(_args),
    fn = args.splice(0, 1)[0];
  return IS_FUNCTION(fn) && fn.apply(null, args);
};

export const GET_PROPERTY = (obj: any, prop: string, defaultVal: any = null) => IS_OBJECT(obj) ?
  (obj.hasOwnProperty(prop) ? obj[prop] : defaultVal) : defaultVal;

export const AS_ARRAY = (val: any): any[] => IS_ARRAY(val) ? val : [val];

export const FROM_ARRAY = (val: any, index: number = 0): any => AS_ARRAY(val)[index];

export const IS_IN_ARRAY = (val: any, arr: any) => AS_ARRAY(arr).indexOf(val) !== -1;

export const NOT_IN_ARRAY = (val: any, arr: any) => !IS_IN_ARRAY(val, arr);

export const SORT_ARRAY = (arr: any, prop: string, reverse: boolean = false): any[] => {
  let _arr = AS_ARRAY(arr).sort((a, b) => {
    let aProp = a[prop], bProp = b[prop];
    return aProp < bProp ? -1 : (aProp > bProp ? 1 : 0);
  });
  return reverse ? _arr.reverse() : _arr;
};

export const ADD_TO_ARRAY = (val: any, arr: any): any[] => {
  let _arr = AS_ARRAY(arr);
  NOT_IN_ARRAY(val, _arr) && _arr.push(val);
  return _arr;
};

export const SHUFFLE_ARRAY = (arr: any): any[] => {
  let
    _arr = [...AS_ARRAY(arr)],
    __arr: any[] = [],
    i: number = 0,
    len: number = arr.length;

  for (; i < len; i++ ){
    let item: any = _arr.splice(GET_RANDOM_INT(_arr.length), 1)[0];
    item && __arr.push(item);
  }

  return __arr;
};

export const TO_OBJECT = (arr: any, prop: string): any => AS_ARRAY(arr).reduce((agg, d) => {
  const val = d.hasOwnProperty(prop) ? d[prop] : null;
  if (IS_NOT_NULL(val)) {
    agg[val] = agg[val] || [];
    agg[val].push(d);
  }
  return agg;
}, {});

export const GET_RANDOM = (val: number = 1, allowNegative: boolean = false) => (Math.random() * val) *
  (allowNegative ? [-1, 1][GET_RANDOM_INT()] : 1);

export const GET_RANDOM_INT = (val: number = 2) => Math.floor(GET_RANDOM(val));

export const GET_RANDOM_BETWEEN = (fromVal: number = 0, toVal: number = 1) => fromVal + GET_RANDOM(toVal - fromVal);

export const GET_RANDOM_FROM_ARRAY = (arr: any[]) => arr[Math.floor(GET_RANDOM(arr.length))];

export const GET_RANDOM_WORD = () => RANDOM_WORDS[Math.floor(GET_RANDOM(RANDOM_WORDS.length))];

export const GET_RANDOM_STRING = (n: number = 0) => {
  let _n = n || (1 + GET_RANDOM_INT(10));
  return CAPITALIZE(d3.range(_n).map(i => `${GET_RANDOM_WORD()}${_n - 1 === i ? '.' : EMPTY_STRING} `).join(''));
};

export const GET_RANDOM_TEXT = (n: number = 0) => {
  let _n = n || (1 + GET_RANDOM_INT(10));
  return `${d3.range(_n).map(GET_RANDOM_STRING).join('')}\r\n`;
};

export const GET_RANDOM_CHAR = () => {
  let len = CHARS.length;
  return CHARS[GET_RANDOM_INT(len)][GET_RANDOM_INT() ? 'toUpperCase' : 'toLowerCase']();
};

export const GET_RANDOM_CHARS = (n: number = 0) => {
  let _n = n < 1 ? 3 + GET_RANDOM_INT(10) : n;
  return d3.range(_n).map(() => GET_RANDOM_CHAR()).join(EMPTY_STRING);
};

export const IS_VALUE_IN_RANGE = (val: number, range: number[]): boolean => val >= range[0] && val <= range[1];

export const GET_VALUE_IN_RANGE = (val: number, range: number[]): number => {
  let _val = val;

  if (_val < range[0]) _val = range[0];
  if (_val > range[1]) _val = range[1];

  return _val;
};

export const PARENT_HAS_CLASS = (target: any, className: string): boolean => {
  let
    _hasClass = false,
    _target = target;

  while (_target) {
    _hasClass = !!_target.classList && _target.classList.contains(className);
    _target = _hasClass ? null : _target.parentNode;
  }

  return _hasClass;
};

export const CAPITALIZE = (str: string): string => str.split('').map((d, i) => i ? d : d.toUpperCase()).join('');

export const GET_RGBA_COLOR = (color: string, opacity: number = 1): string => {
  let rgb = d3.rgb(color);
  ['r', 'g', 'b'].forEach(prop => rgb[prop] = isNaN(rgb[prop]) ? 0 : rgb[prop]);
  return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${opacity})`;
};

export const DO_NOTHING = () => {};

export const LOG_IT = (something: any) => console.log(something);

export const GET_NULL = () => null;

export const GET_TRUE = () => true;

export const GET_FALSE = () => false;

export const CHECK_CLICK_TARGET = (root, target): boolean => {
  let clickedInside = false;

  while (target) {
    if (target === root) {
      target = null;
      clickedInside = true;
    } else target = target.parentNode || null;
  }

  return clickedInside;
};

export const GET_PROPERTIES = (obj: any, keys: any): any => {
  const _props = {};
  IS_OBJECT(obj) && AS_ARRAY(keys).forEach(key => IS_STRING(key) && (_props[key] = obj[key]));
  return _props;
};

export const DIG_OUT = function (..._args) {
  let
    args = Array.from(arguments),
    base = args.splice(0, 1)[0],
    i = 0;

  try {
    let
      val = (base && base[args[i]]) || undefined,
      len = args.length;

    if (val) while ((len - 1) > i++) {
      let _val = val[args[i]];
      if (IS_NOT_UNDEFINED(_val)) {
        val = _val;
      } else {
        val = undefined;
        i = len;
      }
    }
    return val;
  } catch (err) {
    return;
  }

};

export const GET_ALIGNMENT = (customAlign: any = null): string[] => {
  const align = [CENTER, CENTER];

  if (IS_NULL(customAlign)) return align;

  else if (IS_STRING(customAlign)) {
    align[0] = customAlign === LEFT || customAlign === RIGHT ? customAlign : align[0];
    align[1] = customAlign === TOP || customAlign === BOTTOM ? customAlign : align[1];
  }

  else if (IS_ARRAY(customAlign)) {
    align[0] = customAlign[0] || CENTER;
    align[1] = customAlign[1] || CENTER;
  }

  return align;
};

export const OBJECTS_ARE_EQUAL = (obj1, obj2) => Object.keys(obj1).every(key => obj1[key] === obj2[key]);

export const AWAIT = async (delay: number = 0, res: any | null = null) => new Promise(
  resolve => setTimeout(() => resolve(res), delay)
);

export const GET_RANDOM_ID = (
  prefix: any = EMPTY_STRING,
  postfix: any = EMPTY_STRING
) => `` +
  `${IS_NOT_UNDEFINED(prefix) ? (prefix + '_') : EMPTY_STRING}` +
  `${new Date().getTime()}` +
  `_${(GET_RANDOM() + EMPTY_STRING).replace(/\./, EMPTY_STRING)}` +
  `${IS_NOT_UNDEFINED(postfix) ? ('_' + postfix) : EMPTY_STRING}`;

export const RUN_SAFELY = (action, onError: any = DO_NOTHING) => {
  try {
    AS_FUNCTION(action);
  } catch {
    AS_FUNCTION(onError);
  }
};

export const AS_PROMISE = (val) => IS_PROMISE(val) ? val : new Promise(resolve => resolve(val));

export const PIPE = (...args) => new Promise((resolve, reject) => {
  const
    _args = Array.from(args);

  const
    gotError = (err) => {
      _args.length = 0;
      reject(err);
      return true;
    },

    checkError = (val) => (IS_OBJECT(val) && val['error'] && !(val['error'] instanceof StructureObservable)) ? gotError(val) : false,

    doStep = (val = undefined, i = 0) => {
      try {
        if (!checkError(val)) {
          let step = _args[i];
          !step ? resolve(val) :
            AS_PROMISE(IS_PROMISE(step) ? step : (IS_FUNCTION(step) ? AS_FUNCTION(step, val) : () => step))
              .then(_val => doStep(_val, ++i))
              .catch(err => gotError(err));
        }
      } catch (err) {
        reject(err);
      }
    };

  doStep();
});
