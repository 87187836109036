import React from "react";
import {EW_Toggleable} from "../Common/Classes/EW_Toggleable.class";
import pulseService from "./Pulse.service";
import {CLASSNAME, HEIGHT, WIDTH} from "../Core/Constants/PropertiesAndAttributes.cnst";
import {StructureObservable} from "../Core/Observables/StructureObservable";
import {DIG_OUT, PIPE} from "../Core/Helpers/Helpers.misc";
import {AbstractObserver} from "../Core/Abstract/AbstractObserver";
import {EMPTY_STRING, SIZE_LG, SIZE_MD, SIZE_SM, SIZE_XL, SIZE_XS} from "../Core/Constants/ViewClasses.cnst";
import {computed} from "mobx";

export interface I_EW_BlockSize {
  [WIDTH]: number,
  [HEIGHT]: number,
  [CLASSNAME]: string
}

export class GlobalEventsService extends EW_Toggleable {

  public readonly windowSize: StructureObservable = new StructureObservable({
    [WIDTH]: 0,
    [HEIGHT]: 0,
    [CLASSNAME]: EMPTY_STRING
  } as I_EW_BlockSize);

  private _refreshSize = () => {
    const
      {windowSize} = this,
      wndWidth: number = window.innerWidth,
      wndHeight: number = window.innerHeight,
      currentValue: I_EW_BlockSize = windowSize.value;

    const
      getSizeClassName = val => {
        if (val <= 575) return SIZE_XS;
        else if (val >= 576 && val <= 767) return SIZE_SM;
        else if (val >= 768 && val <= 991) return SIZE_MD;
        else if (val >= 992 && val <= 1199) return SIZE_LG;
        else if (val >= 1200) return SIZE_XL;
      };

    if (currentValue[WIDTH] !== wndWidth || currentValue[HEIGHT] !== wndHeight) windowSize.setValue({
      [WIDTH]: wndWidth,
      [HEIGHT]: wndHeight,
      [CLASSNAME]: getSizeClassName(wndWidth)
    });
  };

  @computed
  public get isWideScreen (): boolean {
    const className = DIG_OUT(this.windowSize.value, CLASSNAME);
    return [SIZE_LG, SIZE_XL].some(cn => cn === className);
  }

  constructor () {
    super();

    new AbstractObserver(
      () => pulseService.isReady
    ).getSubscription(
      state => state && PIPE(
        () => pulseService.getSubscription(() => this._refreshSize()),
        () => this.enable()
      )
    );
  }

}

export default new GlobalEventsService();

