import * as d3 from 'd3';
import React from "react";
import {AbstractComponent} from "../../Core/Abstract/AbstractComponent";
import {EMPTY_STRING, IS_ACTIVE} from "../../Core/Constants/ViewClasses.cnst";
import {StringObservable} from "../../Core/Observables/StringObservable";
import {BACKGROUND_IMAGE} from "../../Core/Constants/PropertiesAndAttributes.cnst";
import {inject, observer} from "mobx-react";
import {SharedService} from "../../Services/Shared.service";
import {PIPE} from "../../Core/Helpers/Helpers.misc";
import {BooleanObservable} from "../../Core/Observables/BooleanObservable";

const
  ROOT = `ew-background-image`,
  CUSTOM_CONTENT = `${ROOT}-custom-content`;

@inject('sharedService')
@observer
export class EW_BackgroundImage extends AbstractComponent {

  private readonly isActive: BooleanObservable = new BooleanObservable();

  private readonly sharedService: SharedService;

  private readonly model: StringObservable;

  private readonly root: any = React.createRef();

  componentDidMount(): void {
    const
      {model, sharedService, root, isActive} = this,
      img = d3.select(root.current);

    this.registerSubscriptions(
      model.getSubscription(() => {
        let source = model.value;
  
        /^uploaded/.test(source) &&
        process.env.NODE_ENV === 'development' &&
        (source = `http://localhost:8080/${source}`);
        
        PIPE(
          () => isActive.setValue(false),
          () => sharedService.loadImages(source),
          () => img.style(BACKGROUND_IMAGE, () => `url(${source || null})`),
        ).then(() => isActive.setValue(true));
      })
    );
  }

  render () {
    const
      {props, root, isActive} = this,
      {children, className} = props;

    return <div className={`${ROOT} pos-rel size-cover 
      ${isActive.value ? IS_ACTIVE : EMPTY_STRING}
      ${className || EMPTY_STRING}`} ref={root}>
      {children && <div className={`${CUSTOM_CONTENT} pos-abs top-0 left-0 size-cover`}>{children}</div>}
    </div>;
  }

  constructor (props) {
    super(props);
    this.model = props.model instanceof StringObservable ? props.model : new StringObservable(props.source);
    this.sharedService = props.sharedService;
  }

}

