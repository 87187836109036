import {AbstractCollection} from "../../Core/Abstract/AbstractCollection";
import {AS_FUNCTION, GET_RANDOM_ID, IS_FUNCTION, IS_NOT_NULL, IS_NULL} from "../../Core/Helpers/Helpers.misc";
import {BooleanObservable} from "../../Core/Observables/BooleanObservable";
import {EMPTY_STRING} from "../../Core/Constants/ViewClasses.cnst";
import {CLASSNAME, CONTENT, ID, TYPE} from "../../Core/Constants/PropertiesAndAttributes.cnst";
import {IoMdMail, IoMdWarning} from "react-icons/all";
import React from "react";

export interface I_EW_GrowlMessage {
  [ID]: string,
  [CONTENT]: any,
  [CLASSNAME]: any,
  [TYPE]: any
}

export class EW_GrowlModel {
  
  public static buildErrorMessage = err =>
    <div className={`font-size-20`}>
      <div className={`display-inline-flex flex-direction-column align-center ew-block-height-0-and-half ew-block-width-0-and-half margin-right-20`}>
        <IoMdWarning className={`font-size-40`} />
        <small>{err.status || 'ERROR'}</small>
      </div>
      <div className={`display-inline-block`}>
        <b>{err.statusText}</b>
      </div>
    </div>;

  public static buildInfoMessage = (text, icon: any = null) =>
    <div className={`font-size-20`}>
      {IS_NOT_NULL(icon) ?
        <div className={`display-inline-flex align-center ew-block-height-0-and-half ew-block-width-0-and-half margin-right-20 font-size-40`}>
          {icon}
        </div> : EMPTY_STRING}
      <div className={`display-inline-block`}>
        <b>{text}</b>
      </div>
    </div>;

  public readonly isOpened: BooleanObservable = new BooleanObservable();

  public readonly messages: AbstractCollection = new AbstractCollection();

  public show = (id: any | null = null): EW_GrowlModel => {
    const
      {messages, isOpened} = this;

    IS_NULL(id) ? messages.selectLast() : messages.select(id);

    IS_NOT_NULL(messages.selection) && isOpened.setValue(true);

    return this;
  };

  public hide = (): EW_GrowlModel => {
    this.messages.clearSelection();
    this.isOpened.resetValue();
    return this;
  };

  public addMessage = (content: any, type: string = 'info', className: string = EMPTY_STRING):EW_GrowlModel => {
    this.messages.addItems({
      [ID]: GET_RANDOM_ID(),
      [CONTENT]: content,
      [CLASSNAME]: className,
      [TYPE]: type
    } as I_EW_GrowlMessage);

    return this;
  };

  public clearMessages = () => {
    this.messages.clearItems();
    return this;
  };
}
