import {action, computed, observable} from 'mobx';
import {EMPTY_STRING} from '../Constants/ViewClasses.cnst';
import {AbstractObservable} from '../Abstract/AbstractObservable';

export class StringObservable extends AbstractObservable {

  protected readonly _initialValue: string;

  @observable
  protected _value: string;

  @action
  public setValue (value, save: boolean = false): this {
    this._value = `${value}`;
    return save ? this.saveValue() : this;
  }

  @computed
  public get length (): number {
    return (`${this.value}`).length;
  }

  constructor (initialValue: string = EMPTY_STRING) {
    super();
    this._initialValue = initialValue;
    this.resetValue();
  }

}

