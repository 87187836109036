export const
  IS_OPENED = 'isOpened', 
  IS_CLOSED = 'isClosed',
  IS_COLLAPSED = 'isCollapsed',
  IS_READY = 'isReady',
  IS_ACTIVE = 'isActive',
  IS_ENABLED = 'isEnabled',
  IS_DISABLED = 'isDisabled',
  IS_SELECTED = 'isSelected',
  IS_FOCUSED = 'isFocused',
  IS_VISIBLE = 'isVisible',
  IS_LOADING = 'isLoading',
  IS_EMPTY = 'isEmpty',
  IS_REQUIRED = 'isRequired',
  IS_REVERSED = 'isReversed',
  IS_SORTABLE = 'isSortable',
  IS_SCROLLABLE = 'isScrollable',
  IS_STARTED = 'isStarted',
  IS_FINISHED = 'isFinished',
  IS_VALID = 'isValid',
  IS_ANIMATED = 'isAnimated',
  IS_SCALED = 'isScaled',
  HAS_SELECTION = 'hasSelection',
  HAS_CONTENT = 'hasContent',
  DARK_BACKGROUND = 'darkBackground',
  LIGHT_BACKGROUND = 'lightBackground',
  IS_INTERACTIVE = 'isInteractive',
  NO_SELECT = 'noSelect',
  HAS_NESTED_COLLECTION = 'hasNestedCollection',
  EMPTY_STRING = '',
  EMPTY_SPACE = ' ',
  SIZE_XS = 'xs',
  SIZE_SM = 'sm',
  SIZE_MD = 'md',
  SIZE_LG = 'lg',
  SIZE_XL = 'xl';



