import React from 'react';
import ReactDOM from 'react-dom';
import {EW_Main} from './Main/EW_Main';
import {configure} from 'mobx';
import {Provider} from 'mobx-react';
import sharedService from './Services/Shared.service';
import routingService from './Services/Routing.service';
import accessPointService from './Services/AccessPoint.service';
import userService from './Services/User.service';
import pulseService from './Services/Pulse.service';
import globalEventsService from './Services/GlobalEvents.service';
import * as serviceWorker from './serviceWorker';

configure({enforceActions: 'always'});

ReactDOM.render(
  <Provider
    pulseService={pulseService}
    sharedService={sharedService}
    userService={userService}
    accessPointService={accessPointService}
    globalEventsService={globalEventsService}
    routingService={routingService.run()}>
    <EW_Main />
  </Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
