export const
  BLOG_ITEMS = 'blog-items',
  GET = {
    TEST: 'test',
    ERROR: 'error',
    GET_PAGE: 'get-page',
    GET_PAGES: 'get-pages',
    GET_SECTION: 'get-section',
    GET_SECTIONS: 'get-sections',
    GET_MESSAGES: 'get-messages',
    LOAD_FILE: 'load-file',
    READ_POST: 'read-post'
  },
  POST = {
    TEST: 'test',
    ERROR: 'error',
    SIGN_IN: 'sign-in',
    SIGN_OUT: 'sign-out',
    CHECK_TOKEN: 'check-token',
    CLEAR_TOKEN: 'clear-token',
    UPDATE_PAGE: 'update-page',
    CREATE_MESSAGE: 'create-message',
    UPDATE_MESSAGE: 'update-message',
    RESET_DB: 'reset-db',
    SAVE_FILE: 'save-file',
    SAVE_FILES: 'save-files',
    RESET_SECTION: 'reset-section',
    CREATE_GENERAL_MESSAGE: 'create-general-message',
    CREATE_JOB_SEEKING_MESSAGE: 'create-job-seeking-message',
    CREATE_CASE_STUDY_MESSAGE: 'create-case-study-message',
  
    CREATE_POST: 'create-post',
    DELETE_POST: 'delete-post',
    EDIT_POST: 'edit-post',
    COMMENT_POST: 'comment-post'
  };

