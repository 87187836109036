import {action, computed, observable} from 'mobx';
import {
  AS_ARRAY,
  AS_FUNCTION,
  DIG_OUT,
  GET_NULL,
  IS_NOT_NULL,
  IS_NULL, AWAIT, PIPE, GET_RANDOM_ID
} from '../Helpers/Helpers.misc';
import {StringObservable} from '../Observables/StringObservable';
import {AbstractSubscription} from './AbstractSubscription';
import {StructureObservable} from '../Observables/StructureObservable';
import {AbstractCollectionItem} from './AbstractCollectionItem';
import {EMPTY_STRING} from '../Constants/ViewClasses.cnst';
import {ID, IS_OBSERVABLE} from '../Constants/PropertiesAndAttributes.cnst';

export const
  _GET_ITEM_ = '_get_',
  _GET_COPY_ = '_get_copy_',
  _TOGGLE_ = '_toggle_',
  _IS_SELECTED_ = '_selected_',
  _GET_PROTO_ = '_get_proto_',
  _GET_COLLECTION_ = '_get_collection_',
  _GET_NESTED_ = '_get_nested_',
  _REMOVE_ = '_remove_';

export class AbstractCollection {
  
  public readonly uid: string;
  
  @observable.shallow private readonly _items: Map<string, AbstractCollectionItem> = new Map();
  
  private readonly _trackBy: string;

  private readonly _allowMultiselect: boolean;

  private readonly _getParent: any;

  private readonly _selection: StructureObservable = new StructureObservable();

  private readonly _hash: StringObservable = new StringObservable();
  
  private _newItems: any = {};

  private _index: number = 0;

  private _refreshHash (): this {
    this._hash.setValue(Array.from(this._items.keys()).join('~'));
    return this;
  }

  private _removeItem (datum): this {
    let collectionItem: AbstractCollectionItem = datum && AS_FUNCTION(datum[_GET_ITEM_]);
    if (collectionItem) {
      this._selection.removeKey(collectionItem.id);
      this._items.delete(collectionItem.id);
      delete this._newItems[collectionItem.id];
      collectionItem.nestedCollection && collectionItem.nestedCollection.clearItems();
      [_GET_COLLECTION_, _GET_ITEM_, _GET_COPY_, _GET_NESTED_, _IS_SELECTED_, _TOGGLE_, _GET_PROTO_, _REMOVE_]
        .forEach(method => delete datum[method]);
    }
    return this;
  }

  private _getSelectionHash () {
    return IS_NULL(this.selection) ?
      EMPTY_STRING :
      this.selection.map(d => d[this._trackBy]).join('_');
  }

  public getAsIndexedMap (prop: string | null = null): any {
    prop = IS_NULL(prop) ? this._trackBy : prop;
    return this.items.reduce((agg, d) => {
      let id = DIG_OUT(d, prop);
      id && (agg[id] = d);
      return agg;
    }, {});
  }

  public getItem (id, valueOnly: boolean = true): any | null {
    const item = this._items.get(`${id}`);
    return item ? (valueOnly ? item.datum : item) : null;
  }

  public getItems (valueOnly: boolean = true): any[] {
    return Array.from(this._items.values()).map(d => valueOnly ? d.datum : d);
  }

  public getItemByProperty (val: any, prop: string | null = null) {
    let searchingProperty = `${IS_NULL(prop) ? this._trackBy : prop}`;
    return this.items.find(d => {
      let _val = d[searchingProperty];
      if (_val && _val[IS_OBSERVABLE]) return _val.value === val;
      else return _val === val;
    });
  }
  
  public getNewItems = () => Object.keys(this._newItems).reduce((newItems: any[], key: string) => {
    if (this.hasItem(key)) {
      newItems.push(this.getItem(key));
      delete this._newItems[key];
    }
    return newItems;
  }, []);

  public getSize (): number {
    return this._items.size;
  }

  public hasItem (id): boolean {
    return this._items.has(`${id}`);
  }

  public replaceItems = (items) => PIPE(
    () => this.items.forEach((datum) => this._removeItem(datum)),
    () => AWAIT(),
    () => this.addItems(items)
  );

  public isSelected (d): boolean {
    let item = AS_FUNCTION(d[_GET_ITEM_]);
    return !!(item && this._selection.value[item.id]);
  }

  public subscribeOnSelectionChange (fn, skipFirstCall: boolean = false): AbstractSubscription {
    return this._selection.getSubscription(() => AS_FUNCTION(fn, this.selection), skipFirstCall);
  }

  public subscribeOnCollectionChange (fn, skipFirstCall: boolean = false): AbstractSubscription {
    return this._hash.getSubscription(() => AS_FUNCTION(fn, this.getItems()), skipFirstCall);
  }

  public subscribeOnNextSelectionChange (fn): AbstractSubscription {
    return this._selection.subscribeOnNext(fn);
  }

  public subscribeOnNextCollectionChange (fn): AbstractSubscription {
    return this._hash.subscribeOnNext(fn);
  }

  public selectFirst (): this {
    this.size && AS_FUNCTION(this.items[0][_TOGGLE_], true);
    return this;
  }

  public selectLast (): this {
    this.size && AS_FUNCTION(this.items[this.size - 1][_TOGGLE_], true);
    return this;
  }

  public getCopy (
    collection: AbstractCollection | null | any = null,
    trackBy: string | null = null,
    allowMultiselect: boolean | null = null
  ): AbstractCollection {
    return (IS_NULL(collection) ? new AbstractCollection(
      `${IS_NULL(trackBy) ? this._trackBy : trackBy}`,
      !!(IS_NULL(allowMultiselect) ? this._allowMultiselect : allowMultiselect)
    ) : collection).replaceItems(
      this.getItems(false).map(d => d.getCopy())
    );
  }

  public getItemIndex (id: any = null) {
    let item = IS_NULL(id) ? this.selection : this.getItem(id);
    return item ? this.items.indexOf(item) : null
  }

  public refreshSelection () {
    if (IS_NULL(this.selection)) return this;
    else {
      let selectionHash = Object.keys(this._selection.value).filter(key => this._selection.value[key]);
      return this
        .clearSelection()
        .select(selectionHash);
    }
  }

  @action
  public addItems (items): this {
    const
      self = this,
      {_items, _trackBy, _newItems} = self;

    AS_ARRAY(items).forEach(datum => {
      const
        index = this._index++,
        id = `${datum.hasOwnProperty(_trackBy) ? datum[_trackBy] : index}`,
        item = new AbstractCollectionItem(id, index, datum, () => self);
        
      _items.set(id, item);
      _newItems[id] = item;
    });

    return this._refreshHash();
  }

  @action
  public removeItem (d): this {
    this._removeItem(this.getItem(d));
    return this._refreshHash();
  }

  @action
  public clearItems (): this {
    this.items.forEach((datum) => this._removeItem(datum));
    return this._refreshHash();
  }

  @action
  public select (d): this {
    const
      selectionUpdate = {},
      addToSelectionList = _d => {
        const id = `${_d}`;
        this.hasItem(id) && (selectionUpdate[id] = true);
      },
      needUpdate = () => !!Object.keys(selectionUpdate).length;

    AS_ARRAY(d).forEach(_d => addToSelectionList(_d));

    needUpdate() && (
      this._allowMultiselect ?
        this._selection.setValue(selectionUpdate) :
        this._selection.resetValue().setValue(selectionUpdate)
    );

    return this._refreshHash();
  }

  @action
  public deselect (d): this {
    let id = `${d}`;
    this.hasItem(id) && this._selection.removeKey(id);
    return this._refreshHash();
  }

  @action
  public selectAll(): this {
    const selectionUpdate = {};
    Array.from(this._items.keys()).forEach(key => selectionUpdate[key] = true);
    this._selection.setValue(selectionUpdate);
    return this._refreshHash();
  }

  @action
  public deselectAll (): this {
    Array.from(this._items.keys()).forEach(key => this._selection.removeKey(key));
    return this._refreshHash();
  }

  @action
  public toggle (d): this {
    let id = `${d}`;
    this.hasItem(id) && (this.isSelected(this.getItem(id)) ? this.deselect(id) : this.select(id));
    return this._refreshHash();
  }

  @action
  public clearSelection (): this {
    this._selection.resetValue();
    return this;
  }

  @computed
  public get items (): any[] {
    return this.getItems();
  }

  @computed
  public get selection (): any {
    let
      _selection = this._selection.value,
      keys = _selection && Object.keys(_selection).filter(key => _selection[key]);

    if (this._allowMultiselect) return keys.reduce((selection: any[] | null, key) => {
      let item = this.getItem(key);

      if (item) {
        if (IS_NULL(selection)) selection = [];
        AS_ARRAY(selection).push(item);
      }

      return selection;
    }, null);

    else return this.getItem(keys[0]) || null;
  }

  @computed
  public get size (): number {
    return this.getSize();
  }

  @computed
  public get hash (): string {
    return this._hash.value;
  }

  @computed
  public get hasParent (): boolean {
    return IS_NOT_NULL(this.parent);
  }

  @computed
  public get parent (): AbstractCollectionItem | null {
    return this._getParent();
  }

  constructor(
    trackBy: string = ID,
    allowMultiselect: boolean = false,
    getParent: any = GET_NULL
  ) {
    this.uid = GET_RANDOM_ID();
    this._trackBy = trackBy;
    this._allowMultiselect = allowMultiselect;
    this._getParent = getParent;
  }

}

