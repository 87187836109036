import {NumberObservable} from '../Observables/NumberObservable';
import {AbstractSubscription} from '../Abstract/AbstractSubscription';
import {computed} from 'mobx';
import {AS_FUNCTION, IS_NOT_NULL} from '../Helpers/Helpers.misc';
import {BooleanObservable} from "../Observables/BooleanObservable";

export class AnimationFrameObserver {

  private _core: NumberObservable = new NumberObservable();

  private _isRunning: BooleanObservable = new BooleanObservable();

  private _frame: any = null;

  private _next (): this {
    this._core.setValue(new Date().getTime());
    return this;
  }

  public run (): this {
    const
      nextFrame = () => {
        this._frame = this._isRunning.value ? requestAnimationFrame( () => {
          this._next();
          nextFrame();
        }) : null;
      };

    this._isRunning.value && this.stop();
    this._isRunning.toggleValue(true);
    nextFrame();
    return this;
  }

  public stop (): this {
    this._frame && cancelAnimationFrame(this._frame);
    this._isRunning.toggleValue(false);
    this._frame = null;
    return this;
  }

  public getSubscription (fn: any, skipFirstCall: boolean = false): AbstractSubscription {
    let i = 0;
    return this._core.getSubscription(() => AS_FUNCTION(fn, i++), skipFirstCall);
  }

  public subscribeOnNext (fn) {
    return this._core.subscribeOnNext(fn);
  }

  @computed
  public get isRunning (): boolean {
    return !!this._isRunning.value;
  }

  @computed
  public get tick (): number {
    return this._core.value;
  }
}

export default new AnimationFrameObserver();

