import {computed} from "mobx";
import {BooleanObservable} from "../../Core/Observables/BooleanObservable";

export class EW_Toggleable {

  private readonly _isReady: BooleanObservable = new BooleanObservable();

  public enable = () => this._isReady.setValue(true);

  @computed
  public get isReady (): boolean {
    return this._isReady.value;
  }

}

