import * as d3 from 'd3';
import React, {Suspense, lazy} from 'react';
import {AbstractComponent} from '../Core/Abstract/AbstractComponent';
import './EW_Main.scss';
import {inject, observer} from "mobx-react";
import {BooleanObservable} from "../Core/Observables/BooleanObservable";
import {AbstractObserver} from "../Core/Abstract/AbstractObserver";
import {MobxRouter} from 'mobx-router';
import {RoutingService} from "../Services/Routing.service";
import {SharedService} from '../Services/Shared.service';
import {AccessPointService} from "../Services/AccessPoint.service";
import {UserService} from "../Services/User.service";
import {PulseService} from "../Services/Pulse.service";
import {EMPTY_STRING} from "../Core/Constants/ViewClasses.cnst";

import EW_Loader from '../Components/EW_Loader/EW_Loader';

const
  EW_AppHeader = lazy(() => import('./EW_AppHeader/EW_AppHeader')),
  EW_AppFooter = lazy(() => import('./EW_AppFooter/EW_AppFooter')),
  EW_AdminPanel = lazy(() => import('./EW_AdminPanel/EW_AdminPanel'));

const
  EW_Growl = lazy(() => import('../Components/EW_Growl/EW_Growl'));

const
  ROOT = `ew-main`,
  MAIN_VIEW = `${ROOT}-view`;

@inject('routingService', 'sharedService', 'accessPointService', 'userService', 'pulseService')
@observer
export class EW_Main extends AbstractComponent {

  private readonly isLoading: BooleanObservable = new BooleanObservable(true);

  private readonly routingService: RoutingService;

  private readonly sharedService: SharedService;

  private readonly accessPointService: AccessPointService;

  private readonly userService: UserService;

  private readonly pulseService: PulseService;

  componentDidMount () {
    const
      {routingService, sharedService, userService, accessPointService, pulseService} = this,
      {isLoading, menuState, showBlogConstructor, showLoginForm} = sharedService,
      body = d3.select(document.querySelector('body'));

    const
      appStateObserver = new AbstractObserver(
        () => [
          routingService,
          sharedService,
          userService,
          accessPointService,
          pulseService
        ].every(service => service.isReady)
      ),
      pageScrollObserver = new AbstractObserver(
        () => [
          this.isLoading.value,
          menuState.value,
          showBlogConstructor.value,
          showLoginForm.value
        ].some(val => val)
      ),
      isLoadingObserver = new AbstractObserver(
        () => !appStateObserver.currentValue || isLoading.value
      );

    this.registerSubscriptions(
      appStateObserver.getSubscription(
        state => sharedService.servicesAreReady.setValue(state)
      ),

      isLoadingObserver.getSubscription(
        state => this.isLoading.setValue(state)
      ),

      pageScrollObserver.getSubscription(
        state => body.classed('non-scrollable', state)
      ),

      routingService.routesCollection.subscribeOnSelectionChange(
        () => isLoading.setValue(true)
      )
    );
  }

  render () {
    const {routingService, sharedService, isLoading} = this;

    return <div className={`${ROOT}`}>
      <Suspense fallback={EMPTY_STRING}><EW_AppHeader /></Suspense>
      <div className={`${MAIN_VIEW}`}>
        <MobxRouter store={routingService} />
      </div>
      <Suspense fallback={EMPTY_STRING}><EW_AppFooter /></Suspense>
      {/*<Suspense fallback={EMPTY_STRING}><EW_AdminPanel /></Suspense>*/}
      <EW_Loader isOpened={isLoading}/>
      <Suspense fallback={EMPTY_STRING}><EW_Growl model={sharedService.growl} /></Suspense>
    </div>;
  }

  constructor (props) {
    super(props);
    this.routingService = props.routingService;
    this.sharedService = props.sharedService;
    this.accessPointService = props.accessPointService;
    this.userService = props.userService;
    this.pulseService = props.pulseService;
  }

}

