import axios from 'axios';
import {BooleanObservable} from "../Core/Observables/BooleanObservable";
import {AS_ARRAY, DIG_OUT, IS_NOT_UNDEFINED, IS_OBJECT, IS_STRING, PIPE} from "../Core/Helpers/Helpers.misc";
import {DATA, NAME} from "../Core/Constants/PropertiesAndAttributes.cnst";
import {GET, POST} from "../Common/Constants/EW_Routes.cnst";
import {StringObservable} from "../Core/Observables/StringObservable";
import {computed} from "mobx";
import {EW_Toggleable} from "../Common/Classes/EW_Toggleable.class";
import {ROUTES} from "./Routing.service";
import {EMPTY_STRING} from "../Core/Constants/ViewClasses.cnst";

export const
  USER_KEY = '_usr_';

export class AccessPointService extends EW_Toggleable {

  private virtualLink: HTMLAnchorElement = document.createElement('a');

  private token: StringObservable = new StringObservable();

  private _getHeaders = (customHeaders = {}) => {
    const headers = Object.assign(
      customHeaders,
      this.token.value ? {[USER_KEY]: this.token.value} : {}
    );

    console.log(headers);

    return headers;
  };

  private _getError = err => {
    let
      {response} = err,
      errSource = IS_OBJECT(response.data) ? response.data : response;

    return err['isAxiosError'] ? {
      success: false,
      status: DIG_OUT(errSource, 'status'),
      statusText: DIG_OUT(errSource, 'statusText')
    } : err;
  };

  private _getData = res => DIG_OUT(res, DATA);

  public setToken = (token): AccessPointService => {
    if (IS_NOT_UNDEFINED(token)) {
      localStorage.setItem(USER_KEY, token);
      this.token.setValue(token);
    }
    return this;
  };

  public getToken = () => {
    console.log(this.token);
    return this.token.value;
  };

  public clearToken = (): AccessPointService => {
    localStorage.removeItem(USER_KEY);
    this.token.resetValue();
    return this;
  };

  public sendGet = (url: string, params: any = {}) => new Promise(
    (resolve, reject) => PIPE(
      axios.get(url, {params: params, headers: {[USER_KEY]: this.token.value}}),
      res => this._getData(res),
    )
      .then(res => resolve(res))
      .catch(err => reject(this._getError(err)))
  );

  public sendPost = (url: string, data: any = {}, customHeaders: any = {}) => new Promise(
    (resolve, reject) => PIPE(
      axios.post(url, data, {headers: {[USER_KEY]: this.token.value}}),
      res => {
        url === POST.SIGN_IN && this.setToken(DIG_OUT(res, 'headers', USER_KEY));
        url === POST.SIGN_OUT && this.clearToken();
        return res;
      },
      res => this._getData(res),
    )
      .then(res => resolve(res))
      .catch(err => {
        // console.log(err.response, err.response.data.statusText, DIG_OUT(err.response, 'data', 'statusText'));
        reject(this._getError(err));
      })
  );

  public sendFile = (file: any) => new Promise(
    (resolve, reject) => PIPE(
      () => new FormData(),
      form => {
        form.append('file', file);
        return form;
      },
      form => axios.post(POST.SAVE_FILE, form, {headers: {[USER_KEY]: this.token.value}})
    )
      .then(res => resolve(res))
      .catch((err) => reject(err))
  );
  
  public saveFiles = (files: any, bucketName: string, filesArrayName: string = 'images') => PIPE(
    () => Object.keys(files).reduce((form, key) => {
      form.append(filesArrayName, files[key], key);
      return form;
    }, new FormData()),
    form => axios.post(`${POST.SAVE_FILES}/${bucketName}`, form, {headers: {
      [USER_KEY]: this.token.value
    }}),
    res => DIG_OUT(res, DATA)
  );
  
  public sendForm = (formData: any, targetUrl: string = EMPTY_STRING) => {
    return PIPE(
      () => Object.keys(formData).reduce((form: FormData, key: string) => {
        form.append(key, formData[key]);
        return form;
      }, new FormData()),
      form => {
        return axios.post(targetUrl, form, {headers: {
            [USER_KEY]: this.token.value,
            'content-type': 'multipart/form-data'}
        })
      },
      res => DIG_OUT(res, DATA)
    );
  }

  public loadFile = (file) => new Promise(
    (resolve, reject) => PIPE(
      () => axios.get(GET.LOAD_FILE, {
        params: { file: file[NAME]},
        responseType: 'blob'
      }),
      res => window.URL.createObjectURL(new Blob([res.data])),
      url => {
        const {virtualLink} = this;
        virtualLink.setAttribute('href', url);
        virtualLink.setAttribute('download', file[NAME]);
        virtualLink.click();
        return url;
      }
    )
      .then(res => resolve(res))
      .catch(err => reject(err))
  );

  @computed
  public get hasToken (): boolean {
    return !!this.token.value;
  }

  constructor () {
    super();
    PIPE(
      this.setToken(localStorage.getItem(USER_KEY))
    )
      .finally(() => this.enable());
  }

}

export default new AccessPointService();

