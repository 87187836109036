import {action, computed, observable} from 'mobx';
import {AbstractObserver} from './AbstractObserver';
import {AbstractSubscription} from './AbstractSubscription';
import {ADD_TO_ARRAY, AS_ARRAY, AS_FUNCTION, IS_NOT_NULL, IS_NOT_UNDEFINED} from '../Helpers/Helpers.misc';

export class AbstractObservable {

  protected readonly _initialValue: any;

  @observable
  protected _value: any;

  @observable
  protected _savedValue: any;

  protected _observer: AbstractObserver | undefined;

  protected _allowedValues: any[] | null = null;

  public resetValue (): this {
    return this.setValue(this._initialValue);
  }

  public getSubscription (fn, skipFirstCall: boolean = false): AbstractSubscription {
    if (!this._observer) this._observer = new AbstractObserver(() => this.value);
    return this._observer.getSubscription(fn, skipFirstCall);
  }

  public subscribeOnNext (fn): AbstractSubscription {
    let subscription: AbstractSubscription;

    subscription = this.getSubscription(val => {
      subscription && subscription.unsubscribe();
      AS_FUNCTION(fn, val);
    }, true);

    return subscription;
  }

  public getAsPromise () {
    return new Promise(resolve => this.subscribeOnNext(resolve));
  }

  public setAllowedValues (vals): this {
    this._allowedValues = this._allowedValues || [];
    ADD_TO_ARRAY(this._initialValue, AS_ARRAY(vals)).forEach(val => ADD_TO_ARRAY(val, this._allowedValues));
    return this;
  }

  public clearAllowedValues (): this {
    this._allowedValues = null;
    return this;
  }

  public getAllowedValues (): any[] {
    return (this._allowedValues || []).slice()
  }

  @action
  public setValue (value, save: boolean = false): this {
    if (IS_NOT_NULL(this._allowedValues)) AS_ARRAY(this._allowedValues).includes(value) && (this._value =  value);
    else this._value = value;
    return save ? this.saveValue() : this;
  }

  @action
  public saveValue () {
    this._savedValue = this._value;
    return this;
  }

  @action
  public clearSavedValue (): this {
    this._savedValue = undefined;
    return this;
  }

  @computed
  public get value (): any {
    return this._value;
  }

  @computed
  public get hasChanges (): any {
    return IS_NOT_UNDEFINED(this._savedValue) && this._savedValue !== this.value;
  }

  @computed
  public get isObservable (): boolean {
    return true;
  }

}