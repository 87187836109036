import {AnimationFrameObserver} from "../Core/Observers/AnimationFrameObserver";
import {DO_NOTHING, PIPE} from "../Core/Helpers/Helpers.misc";
import {AbstractSubscription} from "../Core/Abstract/AbstractSubscription";
import {EW_Toggleable} from "../Common/Classes/EW_Toggleable.class";

export class PulseService extends EW_Toggleable {

  private readonly _core: AnimationFrameObserver = new AnimationFrameObserver();

  public getSubscription = (
    fn: any = DO_NOTHING,
    skipFirstCall: boolean = false
  ): AbstractSubscription => this._core.getSubscription(fn, skipFirstCall);

  public getObserver = (): AnimationFrameObserver => this._core;

  constructor () {
    super();
    PIPE(
      this._core.run()
    )
      .finally(() => this.enable());
  }

}

export default new PulseService();
